.navbar{
    display: flex;
    flex-wrap: nowrap;
    width: 50vw;
    height: 60px;
    font-size: 24px;
    color: black;
    position: sticky;
    top: 0;
    z-index: 5;
    a{
        font-size: 18px;
        font-weight: 200;
        margin-bottom: 0;
        padding-bottom: 0;
        position: relative;

    }
    a:hover:after{
        content: '';
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 2px solid black;
    }
}

.scrolled {
    animation: scrolled-activate 1s;
    background-size: 200%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
}
@keyframes scrolled-activate {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.hamburger-menu{
    width: 20px;
    height: 16px;
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span{
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: black;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }
    span:nth-child(1) {
        top: 0px;
      }
    span:nth-child(2) {
        top: 8px;
      }
    span:nth-child(3){
        top: 8px;
    }
    span:nth-child(4) {
        top: 16px;
    }
}

// onClick animation for the .hamburger-menu
.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
  }

.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

.open span:nth-child(4) {
    top: 10px;
    width: 0%;
    left: 50%;
  }



.navbar-links{
    opacity: 0;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 0;
    transition: opacity 1.2s ease-in-out;
    a{
        text-decoration: none;
        color: black;
    }
}

.active{
    opacity: 1;
    flex-grow: 1;
    margin: 0px 3em;
}



@media(max-width: 768px) {
    .navbar {
      width: 100vw;
    }
    .navbar-links{
        margin-left: 1.5em;
        justify-content: space-around;
    }
    .active{
      margin: 0px 20px;
    }
  }
