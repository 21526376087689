.content-container{
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.introCard-container{
    margin-top: 12em;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
        font-size: 4.5em;
        font-weight:600;
        text-shadow: 2px 2px 4px rgba(54, 54, 54, 0.3);
        margin-bottom: 0;
    }
    h2{
        font-style:italic;
        margin-bottom: 0;

    }
}



.about-card-container{
   min-height: 100vh;
   position: relative;
   margin-bottom: 50px;
  //  overflow-x: hidden;
}

.project-card-container{
  position: relative;
  margin-bottom: 150px;
  overflow: hidden;
  padding: 0 50px;
}


.socials-container{
  position: sticky;
  transform: translateX(-165px);
  top: 500px;
  div{
    background-color: red;
    height: 160px;
    width: 200px;
    clip-path: polygon(100% 0, 100% 30%, 30% 100%, 0 100%);
    background: linear-gradient(90deg, rgb(159, 159, 159) 70%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0 3px 10px rgba(0,0,0,0.4);
  }

  .github-icon {
    position: absolute;
    top: 23px;
    left: 168px;
    color: black;
    font-size: 30px;
  }
  .linkedin-icon{
    position: absolute;
    top: 26px;
    left: 172px;
    color: black;
    font-size: 26px;
  }

  .insta-icon{
    position: absolute;
    top: 23px;
    left: 170px;
    color: black;
    font-size: 30px;
  }
}

@media (max-width: 450px){
  .socials-container{
    top: 400px;
  }

  .introCard-container{
    margin-top: 2em;
    h1 {
      font-size: 3.7em;
    }
  }
}


.contact-container{
   border-radius: 5px;
   max-width: 600px;
   min-width: 400px;
   padding: 24px 50px;
   i{
     opacity: 0.7;
     font-size: 48px;
     margin-left: 42%;
     margin-bottom: 40px;
   }
}

.form-button-container{
  position: relative;
}

.anchor {
  position: absolute;
  transform: translateY(-20vh);
}
