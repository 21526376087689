.project-device-iphone-8{
  height: 436px;
  width: 210px;
  margin-left: 48px;
}

.project-device-macbook {
  height: 420px;
  width: 666px;
}

@media (max-width: 1500px){
  .project-device-macbook{
    height: 302px;
    width: 518px;
  }
}

@media (max-width: 1220px) {
    .project-device-macbook {
      height: 259px;
      width: 470px;
    }
}

@media (max-width: 770px) {
  .project-device-macbook {
    height: 216px;
    width: 296px;
  }
}

@media (max-width: 450px) {
  .project-device-macbook {
    display: none;

  }
}

@media (max-width: 450px) {
  .project-device-iphone-8 {
    display: none;

  }
}





.project-card-info{
  // max-height: 450px;
  margin-bottom: 50px;
  // max-width: 600px;
  h1{
    color: black;
  }
}

.tech-icons{
  margin-top: 24px;
  i{
    font-size: 32px;
    margin: 8px;
  }
}
