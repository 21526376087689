
.contact {
  height: 100vh;
}

.alert-email-success{
  opacity: 0;
  transition: opacity .2s ease-in-out;
  display: flex;
  position: absolute;
  white-space: nowrap;
  left: 16px;
  top: 5px;


  p {
    font-size: 16px;
    font-family: 'Staatliches', cursive;
  }

  i {
    font-size: 24px;
    margin-right: 8px;
    color: rgb(0, 218, 112);
  }
}

.alert-active{
  opacity: 1;
}

// .test{
//   display: flex;
//   position: absolute;
//   white-space: nowrap;
//   left: 16px;
//   top: 5px;


//   p{
//       font-size: 16px;
//       font-family: 'Staatliches', cursive;
//   }
//   i{
//     font-size: 24px;
//     margin-right: 8px;
//     color: rgb(0, 218, 112);
//   }
// }
