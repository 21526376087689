.button{
  width: 80px;
  padding: 4px;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0,0,0,0.2);
  border: 2px solid black;
  border-radius: 30px;
  cursor: pointer;
  position: relative;
  color: black;
  font-family: 'Staatliches', cursive;
  a{
    position:absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
  &:hover{
    transform: scale(1.02);
  }
}


.button-send{
  background-color: transparent !important;
  box-sizing: none !important;
  z-index: 3;
}
