.intro-subtitle{
    background: linear-gradient(135deg, rgba(184,134,231,0.8) 46%, rgba(136,134,231,0.9) 100%);

    padding: 16px 32px;
    clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
}

.down-arrow {
  width: 50px;
  height: 50px;
  position: relative;
  animation: down-arrow-anim 1s infinite alternate;

  i{
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 24px;
    color: white;
    text-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);

  }
  span{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
}

@keyframes down-arrow-anim {
  0% {
    margin-top: 180px;
  }

  100% {
    margin-top: 174px;
  }
}
