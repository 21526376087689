@import "~bootstrap/scss/bootstrap";
@import "css/navbar.scss";
@import "css/introCard.scss";
@import "css/containers.scss";
@import "css/aboutCard.scss";
@import 'assets/devices.css';
@import "css/projectCard.scss";
@import "css/buttons.scss";
@import "css/socials.scss";
@import "css/contact.scss";

.App{
    transform: translateZ(0);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: rgb(134,231,184);
    background: linear-gradient(35deg, rgba(134,231,184,1) 13%, rgba(0,148,198,1) 45%, rgba(250,172,182,1) 70%, rgba(184,134,231,1) 100%);
    background-size: 450%;
    position: relative;
    animation: movingGradient 45s ease infinite;
}

.foreground{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgb(255,255,255);
    background: linear-gradient(82deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
    width:100%;
    min-height: 100vh;
}

.content-wrapper{
    position: relative;
    width: 100%;
    z-index: 1;
}


@keyframes movingGradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}
