.about-card{
    background-color: white;
    opacity: 0.7;
    box-shadow: 0 6px 15px rgba(0,0,0,0.2);
    border-radius: 5px;
    max-width: 600px;
    // min-width: 400px;
    min-height: 400px;
    padding: 24px 50px;
    h2{
        font-size: 40px;
        margin-bottom: 18px;
        color: black;
        border-bottom: 3px solid black;
    }
    p{
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 2px;
        color: black;
    }
    span{
        padding-bottom: 4px;
        border-bottom: 2px solid rgba(0,0,0,0.6);
    }
}

.about-skills{
    padding: 20px;
    opacity: 0.85;
    position: relative;
    overflow-x: hidden;
    h2{
        color: black;
    }
    i{
        font-size: 66px;
    }
}



.skill-icon-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, min(80px));
    grid-gap: 18px;
}
@media (max-width: 450px) {
  .about-skills {
    i {
      font-size: 40px;
    }
  }
  .skill-icon-container{
    grid-gap: 8px;
  }
}
